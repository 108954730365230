import { AuthProvider } from "./providers/AuthProvider";
import { ThemeProvider } from "./providers/ThemeProvider";
import RoutePath from "./routes/Routes";

const App = () => {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <AuthProvider>
        <RoutePath />
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
