import { ReactNode, createContext, useContext, useState } from "react";

interface AuthState {
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  login: () => void;
  logout: () => void;
}

const initialState: Omit<AuthState, "login" | "logout"> = {
  isAuthenticated: true,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const AuthContext = createContext<AuthState>(initialState as AuthState);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] =
    useState<Omit<AuthState, "login" | "logout">>(initialState);

  const login = () => setAuthState({ ...authState, isAuthenticated: true });
  const logout = () => setAuthState({ ...authState, isAuthenticated: false });

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
