export const dummyData = [
  {
    id: 1,
    title: "Consultation A",
    description: "Mentorship",
    rating: 4.9,
    price: "$120.00",
  },
  {
    id: 2,
    title: "Consultation B",
    description: "1:1",
    rating: 4.7,
    price: "$110.00",
  },
  {
    id: 3,
    title: "Consultation C",
    description: "Session",
    rating: 4.8,
    price: "$100.00",
  },
  {
    id: 4,
    title: "Consultation D",
    description: "Course",
    rating: 4.6,
    price: "$90.00",
  },
  {
    id: 5,
    title: "Consultation E",
    description: "Mentorship",
    rating: 4.5,
    price: "$80.00",
  },
  {
    id: 6,
    title: "Consultation F",
    description: "1:1",
    rating: 4.4,
    price: "$70.00",
  },
  {
    id: 7,
    title: "Consultation G",
    description: "Session",
    rating: 4.3,
    price: "$60.00",
  },
  {
    id: 8,
    title: "Consultation H",
    description: "Course",
    rating: 4.2,
    price: "$50.00",
  },
];
