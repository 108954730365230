import { useState } from "react";

// import { CardContent } from "../ui/card";
// import BookingCard from "./BookingCard/BookingCard";
// import UserProfileCard from "./ProfileCard/ProfileCard";
// import SocialPost from "./SocialCard/SocialCard";

import { dummyData } from "../../utils/constants/dashboard-dummy-data";

import "./Dashboard.css";

const Dashboard = () => {
  const [filterDescription, setFilterDescription] = useState<string>("");

  const filteredData = filterDescription
    ? dummyData.filter((item) => item.description === filterDescription)
    : dummyData;

  console.log(filterDescription);
  console.log(setFilterDescription);
  console.log(filteredData);
  
  return (
    <div>
      Dashboard
    </div>
    // <div className="flex h-screen">
    //   <div className="w-1/3 h-full bg-zinc-800 p-8 fixed left-0 top-0 flex flex-col justify-center">
    //     <UserProfileCard />
    //   </div>

    //   <div className="w-2/3 bg-neutral-100 flex flex-col overflow-y-auto p-8 space-y-6 ml-auto">
    //     <div className="flex justify-between items-center">
    //       <h1 className="font-bold text-4xl">Welcome to my Dashboard</h1>
    //       <button className="bg-blue-500 text-white rounded-full px-4 py-2 hover:bg-blue-700">
    //         Create a New Page
    //       </button>
    //     </div>
    //     <h6 className="mb-4">
    //       Starting with LetsConnect is easy, fast and free
    //     </h6>
    //     <SocialPost />
    //     <div className="mb-4">
    //       <div className="flex space-x-4 mb-4">
    //         <button
    //           className={`px-4 py-2 rounded-full ${
    //             filterDescription === ""
    //               ? "bg-black text-white"
    //               : "bg-gray-200 text-gray-800 hover:bg-gray-300"
    //           }`}
    //           onClick={() => setFilterDescription("")}
    //         >
    //           All
    //         </button>
    //         {["Mentorship", "1:1", "Session", "Course"].map((description) => (
    //           <button
    //             key={description}
    //             className={`px-4 py-2 rounded-full ${
    //               filterDescription === description
    //                 ? "bg-black text-white"
    //                 : "bg-gray-200 text-gray-800 hover:bg-gray-300"
    //             }`}
    //             onClick={() => setFilterDescription(description)}
    //           >
    //             {description}
    //           </button>
    //         ))}
    //       </div>
    //     </div>
    //     <CardContent className="w-full">
    //       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
    //         {filteredData.map((item) => (
    //           <BookingCard key={item.id} data={item} />
    //         ))}
    //       </div>
    //     </CardContent>
    //   </div>
    // </div>
  );
};

export default Dashboard;
