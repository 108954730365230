import { Menu, Moon, Package2, Search, Sun } from "lucide-react";
import { useState } from "react";

import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";

import { useTheme } from "../../providers/ThemeProvider";

import "./Landing.css";

const Landing = () => {
  const [filterDescription, setFilterDescription] = useState<string>("");

  const { setTheme, theme } = useTheme();

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div className="flex h-screen w-screen flex-col">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <a
            href="/"
            className="flex items-center gap-2 text-lg font-semibold md:text-base"
          >
            <Package2 className="h-6 w-6" />
            <span className="sr-only">Mentor Connect</span>
          </a>
          <a
            href="/dashboard"
            className="text-foreground transition-colors hover:text-foreground"
          >
            CanWeConnect
          </a>
          <a
            href="/mentors"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Find Mentors
          </a>
          <a
            href="/sessions"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Sessions
          </a>
          <a
            href="/courses"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Courses
          </a>
          <a
            href="/resources"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Resources
          </a>
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <a
                href="/"
                className="flex items-center gap-2 text-lg font-semibold"
              >
                <Package2 className="h-6 w-6" />
                <span className="sr-only">Mentor Connect</span>
              </a>
              <a href="/dashboard" className="hover:text-foreground">
                CanWeConnect
              </a>
              <a
                href="/mentors"
                className="text-muted-foreground hover:text-foreground"
              >
                Find Mentors
              </a>
              <a
                href="/sessions"
                className="text-muted-foreground hover:text-foreground"
              >
                Sessions
              </a>
              <a
                href="/courses"
                className="text-muted-foreground hover:text-foreground"
              >
                Courses
              </a>
              <a
                href="/resources"
                className="text-muted-foreground hover:text-foreground"
              >
                Resources
              </a>
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex items-center gap-4 ml-auto">
          <Button variant="secondary">Join as a Mentor</Button>
          <Button variant="secondary">Join as a Mentee</Button>
          <Button variant="secondary">Contact Us</Button>
        </div>
        <Button variant="outline" size="icon" onClick={toggleTheme}>
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </header>
      <main className="flex flex-col items-center justify-center flex-1 px-4">
        <h1 className="text-5xl font-bold mb-2">
          Connect with your mentor now
        </h1>
        <h2 className="text-lg text-muted-foreground mb-4">
          Find the guidance you need to succeed
        </h2>
        <form className="w-full max-w-lg">
          <div className="relative">
            <Input
              type="search"
              value={filterDescription}
              onChange={(e) => setFilterDescription(e.target.value)}
              placeholder="Search for a mentor..."
              className="pl-8 pr-20 py-3 h-16 w-full text-lg rounded-full"
            />
            <Button
              variant="secondary"
              size="default"
              className="absolute right-3 bottom-1/4 h-9 rounded-full flex items-center gap-2"
            >
              <h2 className="text-black dark:text-white">Search</h2>
              <Search className="text-black dark:text-white" size={18} />
            </Button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Landing;
