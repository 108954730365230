import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { z } from "zod";

import { LinkedInLogoIcon } from "@radix-ui/react-icons";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";

import { Button } from "../../ui/button";
import { CardContent } from "../../ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/input";
import { Separator } from "../../ui/separator";

import { IRegisterFormSchema } from "../../../interface/register-form.interface";

const Register = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGoogleAuthLogin, setIsGoogleAuthLogin] = useState<boolean>(false);
  const [isLinkedInAuthLogin, setIsLinkedInAuthLogin] =
    useState<boolean>(false);

  const FormSchema: z.ZodType<IRegisterFormSchema> = z.object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    email: z.string().email("Invalid email address"),
    password: z.string().min(8, "Password must be at least 8 characters"),
    confirmPassword: z
      .string()
      .min(8, "Confirm password must be at least 8 characters"),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { formState } = useForm<IRegisterFormSchema>({
    resolver: zodResolver(FormSchema),
  });

  const handleGoogleAuthLogin = () => {
    setIsGoogleAuthLogin(true);
  };

  const handleLinkedInAuthLogin = () => {
    setIsLinkedInAuthLogin(true);
  };

  const handleFormSubmit = () => {
    setIsLoading(true);
    console.log(formState);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue(e.target.name as keyof IRegisterFormSchema, e.target.value);
    console.log(form.getValues());
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleFormSubmit)}>
        <div className="flex h-screen mb-10">
          <div className="w-1/2 bg-neutral-100 flex flex-col justify-content-center top-0 right-0 h-screen">
            <CardContent className="w-full">
              <h2 className="font-bold text-4xl">Create your page</h2>
              <h6 className="mb-4">
                Starting with LetsConnect is easy, fast and free
              </h6>

              <Button
                className="mb-3 w-full border-b border-zinc-800 hover:bg-zinc-800 text-black hover:text-white hover:shadow-2xl transition-all duration-150 ease-in"
                variant="outline"
                onClick={handleGoogleAuthLogin}
              >
                <FcGoogle className="me-2 text-xl" />{" "}
                {isGoogleAuthLogin && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}{" "}
                Sign up with Google
              </Button>
              <Button
                className="mb-3 w-full border-b border-zinc-800 hover:bg-zinc-800 text-black hover:text-white hover:shadow-2xl transition-all duration-150 ease-in"
                variant="outline"
                onClick={handleLinkedInAuthLogin}
              >
                <LinkedInLogoIcon className="me-2 text-2xl text-[#0072B1]" />{" "}
                {isLinkedInAuthLogin && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Sign up with LinkedIn
              </Button>

              <Separator className="mt-5 text-black" />

              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <div className="mt-5">
                    <FormItem>
                      <FormLabel htmlFor="firstName" className="form-label">
                        First name
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          required
                          pattern="^[a-zA-Z]{1,50}$"
                          id="firstName"
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <div className="mb-3 mt-5">
                    <FormItem>
                      <FormLabel htmlFor="lastName" className="form-label">
                        Last name
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          required
                          pattern="^[a-zA-Z]{1,50}$"
                          id="lastName"
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <div className="mb-3 mt-5">
                    <FormItem>
                      <FormLabel htmlFor="email" className="form-label">
                        Email
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          required
                          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                          id="email"
                          type="email"
                          name="email"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <div className="flex justify-content-between mb-3">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <div className="w-1/2 gap-5">
                      <FormItem>
                        <FormLabel htmlFor="password" className="form-label">
                          Password
                        </FormLabel>

                        <FormControl>
                          <Input
                            {...field}
                            required
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                            id="password"
                            type="password"
                            name="password"
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <div className="w-1/2">
                      <FormItem>
                        <FormLabel
                          htmlFor="confirmPassword"
                          className="form-label"
                        >
                          Confirm Password
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            required
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                            id="confirmPassword"
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />
              </div>

              <Button
                type="submit"
                disabled={isLoading}
                className="w-full mt-5 hover:bg-[#3C1642] hover:shadow-2xl transition-all ease-in duration-75"
                onClick={handleFormSubmit}
              >
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Get Started
              </Button>
            </CardContent>
          </div>

          <div className="w-1/2 h-screen absolute right-0 top-0 flex flex-col justify-content-center bg-zinc-800">
            {/* Add your content here */}
          </div>
        </div>
      </form>
    </Form>
  );
};

export const ValidateUser = () => {
  return <p>auth user sucecss</p>;
};

export default Register;
