import { useState } from "react";
import { CardContent } from "../../../ui/card";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../../ui/input-otp";

const InputOtp = () => {
  // eslint-disable-next-line
  const [value, setValue] = useState<string>("");

  return (
    <div className="flex h-screen">
      <div className="w-1/2 bg-neutral-100 flex flex-col justify-center items-center h-screen">
        <CardContent className="w-full max-w-md p-8">
          <InputOTP maxLength={5} onChange={(value) => setValue(value)}>
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
            </InputOTPGroup>
          </InputOTP>
          <div className="text-sm">
            <>Enter your one-time password.</>
          </div>
        </CardContent>
      </div>

      <div className="w-1/2 h-screen absolute right-0 top-0 flex flex-col justify-content-center bg-zinc-800">
        {/* Add your content here */}
      </div>
    </div>
  );
};

export const ValidateUser = () => {
  return <p>auth user sucecss</p>;
};

export default InputOtp;
