import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  IDetailsFormSchema,
  Option,
} from "../../../../interface/register-form.interface";
import { options } from "../../../../utils/constants/topic-options";

import { Button } from "../../../ui/button";
import { CardContent } from "../../../ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../ui/form";
import { Input } from "../../../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";

import { Loader2 } from "lucide-react";

import "./Details.css";

const Register = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const FormSchema: z.ZodType<IDetailsFormSchema> = z.object({
    phone: z.string().min(10, "Phone Number is required"),
    tagline: z.string().min(10, "Tag line is required"),
    about: z.string().min(1, "About is required"),
    topics: z.array(z.string()).min(1, "Topics is required"),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const { formState } = useForm<IDetailsFormSchema>({
    resolver: zodResolver(FormSchema),
  });

  const handleFormSubmit = () => {
    setIsLoading(true);
    console.log(formState);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue(e.target.name as keyof IDetailsFormSchema, e.target.value);
    console.log(form.getValues());
  };

  // multi select form
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleSelectChange = (value: string) => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      setSelectedOptions((prev) => [...prev, selectedOption]);
    }
  };

  const handleRemovePill = (value: string) => {
    setSelectedOptions((prev) =>
      prev.filter((option) => option.value !== value)
    );
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleFormSubmit)}>
        <div className="flex h-screen mb-10">
          <div className="w-1/2 bg-neutral-100 flex flex-col justify-content-center top-0 right-0 h-screen">
            <CardContent className="w-full">
              <h2 className="font-bold text-4xl">Fill Some More Details</h2>
              <h6 className="mb-4">
                Starting with LetsConnect is easy, fast and free
              </h6>

              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <div className="mt-5">
                    <FormItem>
                      <FormLabel htmlFor="phone" className="form-label">
                        Phone
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          required
                          pattern="^[a-zA-Z]{1,50}$"
                          id="phone"
                          type="text"
                          name="phone"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <FormField
                control={form.control}
                name="tagline"
                render={({ field }) => (
                  <div className="mb-3 mt-5">
                    <FormItem>
                      <FormLabel htmlFor="tagline" className="form-label">
                        Tag line
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          required
                          pattern="^[a-zA-Z]{1,50}$"
                          id="tagline"
                          type="text"
                          name="tagline"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <FormField
                control={form.control}
                name="about"
                render={({ field }) => (
                  <div className="mb-3 mt-5">
                    <FormItem>
                      <FormLabel htmlFor="about" className="form-label">
                        About
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          required
                          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                          id="about"
                          type="text"
                          name="about"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <div className="relative mb-3 mt-5">
                <FormField
                  control={form.control}
                  name="topics"
                  render={() => (
                    <FormItem>
                      <FormLabel>Topics of Mentorship</FormLabel>
                      <Select onValueChange={handleSelectChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a topic" />
                          </SelectTrigger>
                        </FormControl>

                        <SelectContent>
                          {options.map((option, id) => (
                            <SelectItem key={id} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
                <div className="pills-container absolute top-0 left-1 flex flex-wrap gap-2">
                  {selectedOptions.map((option) => (
                    <div
                      key={option.value}
                      className="flex items-center px-1 mt-2 bg-gray-200"
                    >
                      <h6>{option.label}</h6>
                      <button
                        className="bg-none border-none cursor-pointer text-2sm leading-tight"
                        onClick={() => handleRemovePill(option.value)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <Button
                type="submit"
                disabled={isLoading}
                className="w-full mt-5 hover:bg-[#3C1642] hover:shadow-2xl transition-all ease-in duration-75"
                onClick={handleFormSubmit}
              >
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Get Started
              </Button>
            </CardContent>
          </div>

          <div className="w-1/2 h-screen absolute right-0 top-0 flex flex-col justify-content-center bg-zinc-800">
            {/* Add your content here */}
          </div>
        </div>
      </form>
    </Form>
  );
};

export const ValidateUser = () => {
  return <p>auth user sucecss</p>;
};

export default Register;
