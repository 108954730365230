import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Dashboard from "../components/Dashboard/Dashboard";
import AuthLayout from "../components/auth/AuthLayout";
import Login from "../components/auth/Login/Login";
import Details from "../components/auth/Register/Details/Details";
import InputOtp from "../components/auth/Register/InputOtp/InputOtp";
import Register from "../components/auth/Register/Register";
import NotFound from "../components/pages/404";

import Landing from "../components/Landing/Landing";
import { AuthProvider } from "../providers/AuthProvider";

const RoutePath = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
    },
    { path: "/dashboard", element: <Dashboard /> },
    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: <Login />,
        },

        {
          path: "register",
          element: <Register />,
        },
        {
          path: "register/details",
          element: <Details />,
        },
        {
          path: "register/verifyOtp",
          element: <InputOtp />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RoutePath;

createRoot(document.getElementById("root") as HTMLElement).render(
  <AuthProvider>
    <RoutePath />
  </AuthProvider>
);
